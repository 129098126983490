import React from "react"
import styled from "styled-components"
import { breakpoints } from "../components/styles/theme";

const Icon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    viewBox="0 0 16 16"
    version="1.1"
    aria-hidden="true"
  >
    <title>Location</title>
    <path
      d="M11.536 3.46401C12.0004 3.92832 12.3689 
        4.47957 12.6202 5.08629C12.8716 5.693 
        13.0009 6.34329 13.0009 7.00001C13.0009 7.65673 12.8716 8.30701 
        12.6202 8.91373C12.3689 9.52044 12.0004 10.0717 11.536 10.536L8 
        14.07L4.464 10.535C3.99965 10.0707 3.6313 9.51938 3.38 8.91268C3.12869 
        8.30597 2.99934 7.6557 2.99934 6.99901C2.99934 6.34231 3.12869 5.69205 3.38 
        5.08534C3.6313 4.47863 3.99965 3.92736 4.464 3.46301C4.92836 2.99865 5.47963 
        2.63031 6.08633 2.379C6.69304 2.12769 7.34331 1.99835 8 1.99835C8.6567 1.99835 
        9.30696 2.12769 9.91367 2.379C10.5204 2.63031 11.0716 2.99865 11.536 3.46301V3.46401ZM12.596 
        11.596C13.5051 10.687 14.1242 9.5288 14.3751 8.26791C14.6259 7.00702 14.4972 5.70005 
        14.0053 4.5123C13.5133 3.32455 12.6802 2.30936 11.6113 1.5951C10.5423 0.880851 9.2856 0.499619 
        8 0.499619C6.7144 0.499619 5.45767 0.880851 4.38874 1.5951C3.31981 2.30936 2.48669 3.32455 1.99473 
        4.5123C1.50278 5.70005 1.37409 7.00702 1.62494 8.26791C1.87579 9.5288 2.49491 10.687 3.404 
        11.596L6.939 15.132C7.0783 15.2714 7.2437 15.382 7.42576 15.4574C7.60781 15.5329 7.80294 
        15.5717 8 15.5717C8.19707 15.5717 8.3922 15.5329 8.57425 15.4574C8.7563 15.382 8.9217 15.2714 
        9.061 15.132L12.596 11.596ZM8 9.00001C8.53044 9.00001 9.03914 8.78929 9.41422 8.41422C9.78929 
        8.03915 10 7.53044 10 7.00001C10 6.46957 9.78929 5.96087 9.41422 5.58579C9.03914 5.21072 8.53044 
        5.00001 8 5.00001C7.46957 5.00001 6.96086 5.21072 6.58579 5.58579C6.21072 5.96087 6 6.46957 
        6 7.00001C6 7.53044 6.21072 8.03915 6.58579 8.41422C6.96086 8.78929 7.46957 9.00001 8 9.00001Z"
    ></path>
  </svg>
)

const StyledLocationIcon = styled.div`
  display: flex;
  align-items: center;
  svg {
    width: 1.5rem;
    height: 1.5rem;

    @media ${breakpoints.md} {
      width: 2rem;
      height: 2rem;
    }

    fill: rgb(204, 214, 246);
    margin: auto 0;
    margin-right: 1rem;
  }
`

const LocationIcon = () => (
  <StyledLocationIcon>
    <Icon />
    <p>Los Angeles, CA</p>
  </StyledLocationIcon>
)

export default LocationIcon;
